.join-now-phone-number-input-field {
  .tooltip-widget {
    display: inline-block;
    position: absolute;
    right: 0px;
  }
  #keep-me-signed-in-tooltip {
    width: 195px;
  }
}

#join-form label[for*="signInMarketingPreferencesCheckbox"] .checkbox-text > span > span {
    display: block;
}

// SignIn Form (AEM content style changes)
#sign-in-form .aem-content {
  .headline[data-size="30"],
  .headline[data-size="40"],
  .headline[data-size="45"] {
    font-size: 2.5rem;
  }

  .icon-block-content {
    .headline[data-size="30"],
    .headline[data-size="40"],
    .headline[data-size="45"],
    .headline[data-size="15"] {
      font-size: 1.5rem;
    }
  }
}
